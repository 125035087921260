.circle {
	border-radius: 50%;
}
.normal-marking {
	border: 4px solid white;
}

#football-field {
	position: relative;
	border: 5px solid white;
	border-radius: 5px;
	height: 80vh;
	width: 100%;
	display: flex;
	.field-side {
		// background-image: url("./lawn-texture2.jpg");
		background-repeat: round; 
		display: flex;
		justify-content: space-between;
		align-items: center;

		.goal-area {
			display: flex;
			align-items: center;
			z-index: 1;
			.penalty-area {
				width: 50%;
				height: 50%;
			}
			background-image: url("./lawn-texture2.jpg");
		}
		.penalty-circle {
			position: absolute;
			height: 120px;
			width: 120px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.penalty-point {
			height: 10px;
			width: 10px;
			background-color: white;
			z-index: 1;
		}

		.center-circle {
			width: 150px;
			height: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			.center-point {
				width: 20px;
				height: 20px;
				background-color: white;
			}
		}
	}
	.middle-line {
		position: absolute;
		background-color: white;
		z-index: 1;
	}
	.corner-marking {
		position: absolute;
		width: 30px;
		height: 30px;
		border: 4px solid white;
	}
	.corner-marking.top {
		border-top: 0;
		top: 0;
	}
	.corner-marking.bottom {
		border-bottom: 0;
		bottom: 0;
	}
	.corner-marking.right {
		border-right: 0;
		right: 0;
	}
	.corner-marking.left {
		border-left: 0;
		left: 0;
	}
	.corner-marking.top.left {
		border-radius: 0 0 100% 0;
	}
	.corner-marking.top.right {
		border-radius: 0 0 0 100%;
	}
	.corner-marking.bottom.left {
		border-radius: 0 100% 0 0;
	}
	.corner-marking.bottom.right {
		border-radius: 100% 0 0 0;
	}
}

// ------------------------------
// ---Fußballfeld ist HOCHKANT---
// ------------------------------
@media all and (max-aspect-ratio: 1/1) {
	#football-field {
		flex-direction: column;
		.field-side {
			height: 50%;
			width: 100%;
			flex-direction: column;
			.center-circle {
				transform: translateY(50%);
			}
			.goal-area {
				height: 100px;
				width: 60%;
				flex-direction: column;
			}
		}
	}
	.mirrored {
		-moz-transform: scale(1, -1);
		-webkit-transform: scale(1, -1);
		-o-transform: scale(1, -1);
		-ms-transform: scale(1, -1);
		transform: scale(1, -1);
	}

	.middle-line {
		top: calc(50% - 2px);
		height: 4px;
		width: 100%;
	}
	.remove-border {
		transform: translateY(-4px);
		div {
			transform: translateY(-4px);
		}
	}
}

// ------------------------------
// -----Fußballfeld ist QUER-----
// ------------------------------
@media not all and (max-aspect-ratio: 1/1) {
	#football-field {
		.field-side {
			height: 100%;
			width: 50%;
			flex-direction: row;
			.center-circle {
				transform: translateX(50%);
			}
			.goal-area {
				height: 60%;
				width: 100px;
				flex-direction: row;
			}
		}
	}
	.mirrored {
		-moz-transform: scale(-1, 1);
		-webkit-transform: scale(-1, 1);
		-o-transform: scale(-1, 1);
		-ms-transform: scale(-1, 1);
		transform: scale(-1, 1);
	}
	.middle-line {
		height: 100%;
		width: 4px;
		left: calc(50% - 2px);
	}
	.remove-border {
		transform: translateX(-4px);
		div {
			transform: translateX(-4px);
		}
	}
}
