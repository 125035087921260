nav{
	position: absolute;
	margin-bottom: 10px;
	background-color: rgba(255, 255, 255, 0.2);
	width: 100%;

	.logo{
		font-size: 150%;
		margin-left: 1ex;
	}
}