#sponsors {
	// margin: 0px 5%;
	padding: 20px 5%;
	min-height: 100vh;
	h2 {
		margin-top: 0px;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	// background: linear-gradient(140deg, #a8c511, white 8%, white 15%, #a8c511 75%, #276e0e 95%, #002500);
	background-image: url("./lawn-texture2.jpg");
}