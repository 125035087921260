.lawn-piece, .react-card-flip{
	height: 100%;
	width: 100%;
}

.lawn-piece img{
	height: 100%;
	max-height: 200px;
	min-height: 50px;
	max-width: 100%;
	// width: 80%;
	// max-width: 200px;
}

.lawn-piece-front, .lawn-piece-back{
	height: 100%;
	width: 100%;
	font-size: 3.5ex;
	color: #0D2059;
}

.lawn-piece-back{
	padding: 10px;
	.company-description{
		font-size: 1.5ex;
	}
}

// .lawn-piece.overflow:not(.lawn-piece-front){
// 	height: 40%;
// 	width: 40%;
// 	margin: auto;
// 	margin: 30%;
// 	position: absolute;
// 	background-color: white;
// }