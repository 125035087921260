#homepage {
	height: 100vh;
	background: url("./background.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: right;
	.title-wrapper {
		display: flex;
		// background-color: red;
		flex-direction: column;
		margin-right: 5%;
		h1 {
			font-weight: 700;
			margin-bottom: 40px;
		}
		a {
			display: inline;
			background-color: #663399;
			padding: 20px 45px;
			font-size: 2.5ex;
			color: white;
			cursor: pointer;
		}
		a:hover{
			transition: .3s;
			background-color: #8134d8;
			cursor: pointer;
		}
	}
}