.sponsor-showoff {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.sponsor-container {
	position: relative;
	height: 100%;
	width: 100%;
}

.sponsor-tile {
	position: absolute;
	border: 1px dotted white;
	z-index: 2;
}
