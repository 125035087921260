.down-arrow {
	position: absolute;
	bottom: 0;
	left: 50%;
	animation: bounceUpAndDown 1.5s linear;
	animation-iteration-count: infinite;
}
@keyframes bounceUpAndDown {
	from{
		transform: translate(-50%, 0px);
	}
	50%{
		transform: translate(-50%, 10px);
	}
	to{
		transform: translate(-50%, 0px);
	}
}
.down-arrow:hover{
	cursor: pointer;
}